<template>

    <router-view></router-view>

</template>

<script>
  export default {
    name: "public"
  }
</script>

<style >
    #logo > div{width: 100%;}
    #logo{box-shadow: none;background: transparent}
    @media screen and (min-width: 760px) {
        #logo > div{width: 20vw;}
    }
    @media screen and (min-width: 1200px){
        #logo > div{width: 300px;}
    }
</style>
